<template>
    <div class="listItem">
      <PullRefresh
          :disabled="isRefreshDisable"
          v-if="!isLoading"
          :loading="loading"
          :refreshing="refreshing"
          :finished="finished"
          @onLoad="onLoad"
          @onRefresh="onRefresh"
          :isNoData="isNoData"
          :error="error"
          :is-higeht-max="true"
          :hasAdvPagination="false"
      >
        <div class="threeBox" v-if="showType == 3">
          <HorizontalVideoBox
              class="videoBox"
              :videoInfo="item"
              :current-index="index"
              :videoList="videoList"
              v-for="(item,index) in videoList"
              :key="item.id"
              :isAcg="isAcg"
          />
        </div>
        <div class="rowsBox" v-else>
          <HorizontalVideoBox
              class="videoBox"
              :videoInfo="item"
              :current-index="index"
              :videoList="videoList"
              v-for="(item,index) in videoList"
              :key="item.id"
              :isAcg="isAcg"
          />
        </div>
      </PullRefresh>
      <Loading v-else />
    </div>
</template>

<script>
  import {queryHistoryVideo} from "@/utils";
  import PullRefresh from "@/components/PullRefresh/index.vue";
  import HorizontalVideoBox from "@/components/HorizontalVideoBox/index.vue";
  import Loading from "@/components/Loading/index.vue";
  import {querySubModuleList} from "@/api/app";

  export default {
    name: "HistoryList",
    components: {Loading, HorizontalVideoBox, PullRefresh},
    props: {
      subModuleId: {
        type: String,
      },
      moduleSort:{
        type: Number
      },
      showType: {
        type: Number,
      },
      isAcg: {
        type: Boolean,
      },
      isBanner: {
        type: Boolean,
        default: true,
      },
      isNotice: {
        type: Number,
      },
    },
    data(){
      return {
        isRefreshDisable: false,
        isLoading: true,
        loading: true,
        refreshing: false,
        finished: false,
        isNoData: false,
        error: false,
        videoList:[],
        pageNumber: 1,
        pageSize: 10,
      }
    },
    mounted() {
      this.querySubModuleList('refresh');
    },
    methods:{
      async querySubModuleList(type) {
        let req = {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          subModuleId: this.subModuleId,
          moduleSort: this.moduleSort || 1,
        };
        let res = await this.$Api(querySubModuleList, req);
        console.log(res);
        this.loading = false;
        this.refreshing = false;
        this.isLoading = false;
        const list = this.isAcg?res.data.allMediaInfo:res.data.allVideoInfo || [];
        if (type === "refresh") {
          this.videoList = list;
        } else {
          this.videoList = this.videoList.concat(list);
        }
        if (this.pageNumber === 1 && this.videoList.length === 0) {
          this.isNoData = true;
        }
        if (!res.data.hasNext) {
          this.finished = true;
        }
      },
      uniqueFun(arr, type) {
        const res = new Map();
        return arr.filter((a) => !res.has(a[type]) && res.set(a[type], 1));
      },
      onRefresh() {
        this.pageNumber = 1;
        this.refreshing = true;
        this.finished = false;
        this.isNoData = false;
        this.querySubModuleList('refresh');
      },
      onLoad() {
        this.loading = true;
        if (this.error) {
          this.error = false;
        } else {
          this.pageNumber++
        }
        this.querySubModuleList();
      }
    }
  }
</script>

<style scoped lang="scss">
  .listItem{
    height: calc(100vh - 40px);

    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    .swiperBox {

      .swipe {
        width: 100vw;
        height: calc(100vw / 2.4);

        .van-swipe-item {
          width: 100%;
          color: #fff;
          font-size: 20px;
          line-height: 150px;
          text-align: center;
          background-color: rgb(43, 39, 72);
        }

        /deep/ .van-swipe__indicators {
          left: auto;
          right: 0;
          align-items: center;
        }

        /deep/ .van-swipe__indicator {
          width: 6px;
          height: 6px;
          background-color: rgba(148, 214, 218, 0.3);
        }

        /deep/ .van-swipe__indicator--active {
          width: 9px;
          height: 9px;
          background-color: #94d6da !important;
        }

        /deep/ img {
          object-fit: fill !important;
        }

        /deep/ .van-image__error{
          border-radius: 0;
        }
      }
    }

    .barTopic {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      // justify-content: space-between;
      //padding: 12px 0;
      padding: 0 10px;
      margin-top: 20px;
      font-size: 10px;
      color: #000000;
      text-align: center;
      //border-bottom: solid rgb(230, 230, 230) 1px;
      box-sizing: border-box;
      overscroll-behavior: contain;

      .barTopicItem {
        //width: 86px;
        flex: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 24px;
      }

      .barTopicItem:last-child {
        padding-right: 30px;
      }

      .topicImg {
        width: 50px;
        height: 50px;
        margin-bottom: 16px;
      }

      img {
        width: 42px;
        height: 42px;
      }

    }
    .threeBox {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 10px;
      margin-top: 4px;
      .videoBox {
        width: 112px;
        margin-top: 8px;
        /deep/ .cover {
          height: 166px;
        }
      }
    }

    .rowsBox {
      // margin-top: 12px;
      padding: 0 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .videoBox {
        width: 174px;
        /deep/ .cover {
          height: 254px;
        }
        margin-top: 12px;
      }
    }
  }
</style>