<!-- 短视频 -->
<template>
  <div class="shortVideo overflow-y-auto">
    <van-tabs class="tabs" v-model="active" @change="change">
      <van-tab title="最新">
        <ListItem :isBanner="false" :subModuleId="itemModules.id" :moduleSort ="1" v-if="itemModules"></ListItem>
      </van-tab>
      <van-tab title="最热">
        <ListItem :isBanner="false" :subModuleId="itemModules.id" :moduleSort ="2" v-if="itemModules"></ListItem>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
  import ListItem from "./ListItem/index.vue";
  import store from "@/store";

  export default {
    name: 'ShortVideo',
    components: {
      ListItem,
    },
    data(){
      return {
        active:0,
        itemModules: null,
      }
    },
    mounted() {
      let modulesList = this.$store.getters.modulesInfo?.sp || [];
      if(modulesList && modulesList.length > 0){
          this.itemModules = modulesList[0];
      }
    },methods:{
      change(index){
        store.commit('app/SET_SCROLL_INFO', {
          key: this.$route.path,
          index: index,
        });
      }
    }
  }
</script>

<style scoped lang="scss">
  .shortVideo{
    .tabs{
      //margin-top: 20px;

      /deep/ .van-tabs__nav{
        background: transparent;
      }

      /deep/ .van-tab{
        font-size: 10px;
        padding: 0;
        border: none;
        color: #B09FD9;
        font-weight: 400;
      }

      /deep/ .van-tab--active{
        border-radius: 8px;
        background: #777;
        border: none;
        font-weight: 400;
        color: #FFF;
      }

      /deep/ .videoBox{
        margin-bottom: 12px;
      }

      /deep/ .van-tabs__line{
        display: none;
      }

      /deep/ .van-tabs__wrap{
        border-radius: 8px;
        height: 26px;
        margin: 20px 23px;
        background: #333;
        border: none;
        padding: 2px 4px;
      }

      /deep/ .coverBox{
        background: #0C0C0C;

        .title{
          color: #FFFFFF;
        }
      }
    }
  }
</style>